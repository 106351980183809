import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 80px 165px;
  ${({ theme }) => theme.placeholders.maxWidth};
  p {
    margin: 4px auto;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 80px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 70px 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 70px 20px;
  }
`

export const ScreensContainer = styled.article`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 55px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 20%;

    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wm}) {
      width: 40%;
      margin-bottom: 40px;
    }

    .title {
      white-space: nowrap;
      font-size: 18px;
      margin-bottom: 25px;
    }

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.ws}) {
    display: none;
  }
`
