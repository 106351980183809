import {
  InnerArticle,
  StyledButton,
  StyledText,
} from 'components/common'
import React from 'react'
import {
  ArticleContainer,
  Wrapper,
} from './styled-components'
import { CourseBuildingProps } from 'interfaces/PageInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'
import { GatsbyImage } from 'gatsby-plugin-image'

const CourseBuildingSection = ({
  header,
  descriptionTop,
  descriptionBottom,
  innerArticleTexts,
  sectionImage,
}: CourseBuildingProps) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      <ArticleContainer>
        <div>
          {isValidElement(descriptionTop) && (
            <StyledText
              variant="light"
              className="subheader"
            >
              {descriptionTop}
            </StyledText>
          )}
          {innerArticleTexts.map((text, index) => (
            <InnerArticle key={index}>{text}</InnerArticle>
          ))}
          {isValidElement(descriptionBottom) && (
            <StyledText
              variant="light"
              className="last-paragraph"
            >
              {descriptionBottom}
            </StyledText>
          )}
          <StyledButton
            to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            paddings={74}
          >
            See it in action
          </StyledButton>
        </div>
        <GatsbyImage
          image={sectionImage.gatsbyImage}
          alt="section-image"
        />
      </ArticleContainer>
    </Wrapper>
  )
}

export default CourseBuildingSection
