// array will include screenshot src in the future

const Screens = [
  {
    title: 'Module Tests & Quizzes',
    relativePath: 'images/screenshots/quizzes.png',
  },
  {
    title: 'Transcripts',
    relativePath: 'images/screenshots/transcript.png',
  },
  {
    title: 'Course Notes',
    relativePath: 'images/screenshots/notes.png',
  },
  {
    title: 'Flashcards',
    relativePath: 'images/screenshots/flashcards.png',
  },
]

export default Screens
