import styled from 'styled-components'

export const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .custom-button {
    width: 178px;
  }

  flex-direction: column;
  align-items: center;

  .title {
    color: rgba(17, 11, 80, 1);
  }
`

export const Banner = styled.section`
  width: 100%;
  position: relative;
  height: 90vh;
  z-index: 1;

  & > .banner-background {
    width: 100%;
    position: absolute;
    object-fit: cover;
  }

  & > .banner-background > :first-child {
    width: 100%;
    max-width: 100% !important;
  }

  & > .banner-sparkle {
    width: 48px;
    height: 56px;
    position: absolute;
  }

  & > .sparkle-1 {
    left: 3%;
    bottom: 40%;
  }

  & > .sparkle-2 {
    left: 18%;
    top: 20%;
  }

  & > .sparkle-3 {
    left: 35%;
    bottom: 30%;
  }

  & > .sparkle-4 {
    right: 35%;
    top: 20%;
  }

  & > .sparkle-5 {
    right: 10%;
    top: 20%;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.placeholders.maxWidthBanner};
  align-items: center;
  padding: 12% 165px 0 165px !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 160px 100px 0 100px !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 150px 50px 0 50px !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 140px 20px 0 20px !important;
  }

  & > .bannerSvg {
    width: 50%;
  }

  .responsive {
    display: none;
  }

  .banner-text {
    margin: 20px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column;
    div {
      width: 100%;
      .title {
        text-align: center;
      }
      .custom-button {
        width: 100%;
        justify-content: center;
      }
    }
    .bannerSvg {
      width: 100%;
      height: 100%;
      margin-block: 20px;
    }
    .responsive {
      display: block;
      width: 100%;
    }
  }
`
