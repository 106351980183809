import { graphql } from 'gatsby'
import defineHtmlAst from 'helpers/defineHtmlAst'
import indexAscendingSort from 'helpers/indexAscendingSort'
import { IEducatorsProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'

import {
  BannerSection,
  DigitalLearningSection,
  HumanToDigitalSection,
  ProvideSection,
  EnchanceSection,
  MobileScreensSection,
  ProvideToolsSection,
  OrganizingSection,
  CourseBuildingSection,
  // SafeLearningSection,
  SuadaHelpSection,
} from 'sections/Educators'

const Educators = ({ data }: IEducatorsProps) => {
  const {
    bannerText,
    missionText,
    digitalGap,
    digitalClassroomText,
    digitalClassroomArticles,
    courseDelivery,
    featuresArticles,
    digitalExperienceText,
    digitalExperienceArticles,
    mobileScreensText,
    mobileScreens,
    measureGrowth,
    digitalPortfolio,
    suadaHelpArticles,
    courseBuilding,
  } = data

  digitalGap.innerArticleTexts = defineHtmlAst(
    digitalGap.innerArticles,
  )

  courseDelivery.innerArticleTexts = defineHtmlAst(
    courseDelivery.innerArticles,
  )

  courseBuilding.innerArticleTexts = defineHtmlAst(
    courseBuilding.innerArticles,
  )

  const humanToDigitalArticles = [
    {
      question:
        digitalClassroomArticles.block4DigitalClassroomArticle1Question,
      answer:
        digitalClassroomArticles
          .block4DigitalClassroomArticle1Answer
          .block4DigitalClassroomArticle1Answer,
    },
    {
      question:
        digitalClassroomArticles.block4DigitalClassroomArticle2Question,
      answer:
        digitalClassroomArticles
          .block4DigitalClassroomArticle2Answer
          .block4DigitalClassroomArticle2Answer,
    },
    {
      question:
        digitalClassroomArticles.block4DigitalClassroomArticle3Question,
      answer:
        digitalClassroomArticles
          .block4DigitalClassroomArticle3Answer
          .block4DigitalClassroomArticle3Answer,
    },
  ]

  const digitalArticles = [
    {
      title:
        digitalExperienceArticles.block6DigitalExperienceArticle1Title,
      description:
        digitalExperienceArticles
          .block6DigitalExperienceArticle1Description
          .block6DigitalExperienceArticle1Description,
    },
    {
      title:
        digitalExperienceArticles.block6DigitalExperienceArticle2Title,
      description:
        digitalExperienceArticles
          .block6DigitalExperienceArticle2Description
          .block6DigitalExperienceArticle2Description,
    },
    {
      title:
        digitalExperienceArticles.block6DigitalExperienceArticle3Title,
      description:
        digitalExperienceArticles
          .block6DigitalExperienceArticle3Description
          .block6DigitalExperienceArticle3Description,
    },
  ]

  const featuresArticlesSorted = featuresArticles.nodes
    .sort(indexAscendingSort)
    .slice(0, 3)

  return (
    <>
      <Helmet title="Educators" defer={false} />
      <BannerSection {...bannerText} />
      <DigitalLearningSection
        {...missionText}
        digitalGap={digitalGap}
      />
      <HumanToDigitalSection
        {...digitalClassroomText}
        articles={humanToDigitalArticles}
      />
      <ProvideSection
        {...courseDelivery}
        articles={featuresArticlesSorted}
      />
      <EnchanceSection
        {...digitalExperienceText}
        articles={digitalArticles}
      />
      <MobileScreensSection
        {...mobileScreensText}
        screens={mobileScreens.nodes}
      />
      <ProvideToolsSection {...measureGrowth} />
      <OrganizingSection {...digitalPortfolio} />
      <CourseBuildingSection {...courseBuilding} />
      {/* <SafeLearningSection /> */}
      <SuadaHelpSection
        articles={suadaHelpArticles.nodes}
      />
    </>
  )
}

export default Educators

export const pageData = graphql`
  query {
    bannerText: contentfulEducatorsPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    missionText: contentfulEducatorsPage {
      mission: block2MissionHeader
      description: block2MissionSubHeader
    }
    digitalGap: contentfulEducatorsPage {
      footer: block3DigitalGapFooter
      title: block3DigitalGapTitle
      innerArticles: block3DigitalGapList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    digitalClassroomText: contentfulEducatorsPage {
      header: block4DigitalClassroomTitle
    }
    digitalClassroomArticles: contentfulEducatorsPage {
      block4DigitalClassroomArticle3Question
      block4DigitalClassroomArticle3Answer {
        block4DigitalClassroomArticle3Answer
      }
      block4DigitalClassroomArticle2Question
      block4DigitalClassroomArticle2Answer {
        block4DigitalClassroomArticle2Answer
      }
      block4DigitalClassroomArticle1Question
      block4DigitalClassroomArticle1Answer {
        block4DigitalClassroomArticle1Answer
      }
    }
    courseDelivery: contentfulEducatorsPage {
      title: block5CourseDeliveryTitle
      description: block5CourseDeliveryDescription {
        description: block5CourseDeliveryDescription
      }
      innerArticles: block5CourseDeliveryList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    featuresArticles: allContentfulHomePageFeaturesArticle {
      nodes {
        title
        description
        index
      }
    }
    digitalExperienceText: contentfulEducatorsPage {
      header: block6DigitalExperienceTitle
    }
    digitalExperienceArticles: contentfulEducatorsPage {
      block6DigitalExperienceArticle3Title
      block6DigitalExperienceArticle3Description {
        block6DigitalExperienceArticle3Description
      }
      block6DigitalExperienceArticle2Title
      block6DigitalExperienceArticle2Description {
        block6DigitalExperienceArticle2Description
      }
      block6DigitalExperienceArticle1Title
      block6DigitalExperienceArticle1Description {
        block6DigitalExperienceArticle1Description
      }
    }
    mobileScreensText: contentfulEducatorsPage {
      header: block7SupportingTitle
      subHeader: block7SupportingSubheader
    }
    mobileScreens: allContentfulEducatorsMobileScreenshot {
      nodes {
        title
        screenshot {
          gatsbyImage(
            width: 400
            quality: 90
            placeholder: BLURRED
          )
        }
      }
    }
    measureGrowth: contentfulEducatorsPage {
      header: block8ProvideTitle
      description: block8ProvideDescription {
        description: block8ProvideDescription
      }
      preHeader: block8PreCourseTitle
      preDescription: block8PreCourseDescription {
        description: block8PreCourseDescription
      }
      postHeader: block8PostCourseTitle
      postDescription: block8PostCourseDescription {
        description: block8PostCourseDescription
      }
    }
    digitalPortfolio: contentfulEducatorsPage {
      header: block9DigitalPortfolioTitle
      subHeader: block9DigitalPortfolioSubHeader
      progression: block9DigitalPortfolioArticle1
      score: block9DigitalPortfolioArticle2
      skillTestVideo: block9DigitalPortfolioArticle3
      certification: block9DigitalPortfolioArticle4
      mobileScreenshot: block9DigitalPortfolioSectionImage {
        gatsbyImage(
          width: 500
          quality: 100
          placeholder: BLURRED
        )
      }
    }
    suadaHelpArticles: allContentfulHomePageSuadaHelpArticle {
      nodes {
        title
        description
      }
    }
    courseBuilding: contentfulEducatorsPage {
      header: block10CourseBuildingTitle
      descriptionTop: block10CourseBuildingDescription
      innerArticles: block10CourseBuildingList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
      descriptionBottom: block10CourseBuildingDescriptionBottom
      sectionImage: block10CourseBuildingSectionImage {
        gatsbyImage(
          width: 700
          quality: 100
          placeholder: BLURRED
        )
      }
    }
  }
`
