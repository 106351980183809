import React from 'react'
import { StyledText } from 'components/common'
import {
  Article,
  ChildWrapper,
  TextContainer,
  Wrapper,
} from './styled-components'

import EducatedManSvg from 'images/svg/analyticMan.svg'
import { isValidElement } from 'helpers/defineHtmlAst'

const humanToDigitalSection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader: string
  articles: {
    question: string
    answer: string
  }[]
}) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      {isValidElement(subHeader) && (
        <StyledText
          textAlign="center"
          variant="light"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <ChildWrapper>
        <EducatedManSvg className="left-svg" />
        <TextContainer>
          {articles.map(({ question, answer }, index) => {
            return (
              <Article key={index}>
                <p className="question">{question}</p>
                <p>{answer}</p>
              </Article>
            )
          })}
        </TextContainer>
      </ChildWrapper>
    </Wrapper>
  )
}

export default humanToDigitalSection
