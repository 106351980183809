import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 122px;
  justify-content: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 65px 165px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 65px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 45px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 25px 20px;
  }
`

export const IntroduceSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 125px;
  margin-bottom: 88px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding-inline: 165px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding-top: 100px;
    padding-inline: 100px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding-top: 50px;
    padding-inline: 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 48px 20px 0 20px;
    margin-bottom: 50px;
    .title {
      font-size: 16px;
    }
    .label {
      font-size: 18px;
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 25px 20px 0 20px;
  }

  & > p {
    text-align: center;
  }
`

export const FlexArticleContainer = styled.article`
  display: flex;
  justify-content: space-between;
  gap: 150px;
  align-items: center;

  & > :first-child {
    width: 45%;
    & > :last-child {
      padding-left: 50px;

      p {
        margin-bottom: 32px;
      }
    }

    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wxs}) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      p {
        text-align: center;
        font-size: 20px;
        margin-block: 20px;
      }
      & > :last-child {
        padding-left: 0;
        width: 100%;

        button,
        .link {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .responsive {
    display: none;
    text-align: center;
    font-size: 24px;
    width: 100%;
    margin-bottom: 15px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    gap: 0px;
    flex-direction: column-reverse;

    .top-responsive {
      display: none;
    }

    .responsive {
      display: block;
    }

    & > svg {
      width: 90%;
    }
  }
`
