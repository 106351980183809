import React from 'react'
import { StyledButton, StyledText } from 'components/common'
import { FlexContainer, Wrapper } from './styled-components'

import PreCourseSvg from 'images/svg/preCourseAssessment.svg'
import PostCourseSvg from 'images/svg/postCourseAssessment.svg'
import { MeasureGrowthProps } from 'interfaces/PageInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'

const ProvideToolsSection = ({
  header,
  description,
  preHeader,
  preDescription,
  postHeader,
  postDescription,
}: MeasureGrowthProps) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      <FlexContainer>
        <StyledText
          variant="light"
          className="pre-course subheader"
        >
          {description.description}
        </StyledText>
        <div className="inner-container">
          <div>
            <div className="svg">
              <PreCourseSvg />
            </div>
            <div className="text-container">
              <StyledText fontSize="16px">
                {preHeader}
              </StyledText>
              {isValidElement(preDescription) && (
                <StyledText
                  fontSize="16px"
                  fontType="regular"
                  fontWeight={400}
                  textColor="rgba(99, 99, 105, 1)"
                >
                  {preDescription.description}
                </StyledText>
              )}
            </div>
          </div>
          <div>
            <div className="svg">
              <PostCourseSvg />
            </div>
            <div className="text-container">
              <StyledText fontSize="16px">
                {postHeader}
              </StyledText>
              {isValidElement(postDescription) && (
                <StyledText
                  fontSize="16px"
                  fontType="regular"
                  fontWeight={400}
                  textColor="rgba(99, 99, 105, 1)"
                >
                  {postDescription.description}
                </StyledText>
              )}
            </div>
          </div>
        </div>
      </FlexContainer>
      <StyledButton
        to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        paddings={74}
      >
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default ProvideToolsSection
