import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 165px 40px 165px;
  ${({ theme }) => theme.placeholders.maxWidth};

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 80px 100px 30px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 80px 50px 30px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    .mobile-screen {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 40px 20px;
  }
`

export const ArticleContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 50px;
  width: 55%;

  & > :last-child {
    width: 60%;
    @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    width: 100%;
  }
`

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 100px;
    border: 1px solid #06c68f;
    padding: 12px 16px;

    & > p {
      font-weight: bold;
      font-family: 'Poppins-bold';
      width: 80%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    width: 100%;
    & > div > p {
      font-size: 15px;
    }
  }
`
