import styled from 'styled-components'

export const SwiperContainer = styled.div`
  width: 100%;

  .screenshot-slider {
    .swiper-wrapper {
      transform: translate3d(0, 0, 0);
    }
    .swiper-pagination {
      bottom: 0px;

      .swiper-pagination-bullet {
        background: #baccc1;
        width: 24px;
        height: 4px;
        border-radius: 2px;
      }

      .swiper-pagination-bullet-active {
        background: #06c68f;
      }
    }
    .slide {
      margin-block: 30px;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      .gatsby-image-wrapper {
        width: 80%;
      }

      h6 {
        font-size: 18px;
        font-family: 'Poppins-bold', sans-serif;
        margin-bottom: 20px;
      }
      svg {
        width: 50%;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.ws}) {
    display: none;
  }
`
