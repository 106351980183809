import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 70px 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 70px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 20px;
    gap: 20px;
  }
`

export const ChildWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 120px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column;
    gap: 20px;

    & > svg {
      display: none;
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 40%;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    width: 100%;
    gap: 32px;
  }
`

export const Article = styled.div`
  position: relative;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: rgba(99, 99, 105, 1);
  .question {
    position: absolute;
    top: 1px;
    left: -80px;
    text-align: right;
    width: 60px;
    font-family: 'Poppins-Bold', sans-serif;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .question {
      position: relative !important;
      left: unset;
      top: unset;
      width: unset;
      text-align: left;
    }
  }
`
