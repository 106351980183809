import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import BannerWoman from 'images/svg/bannerWomen.svg'
import React from 'react'
import {
  TextContainer,
  FlexWrapper,
} from './styled-components'

import BannerSparkle from 'images/svg/bannerSparkle.svg'
import { TitleProps } from 'interfaces/PageInterfaces'

const BannerSection = ({
  header,
  subHeader,
}: TitleProps) => {
  const sparkleMap = [1, 2, 3, 4, 5]
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'
  return (
    <WaveWrapper color="lightGreen">
      {sparkleMap.map((sparkle, index) => {
        const className = `banner-sparkle sparkle-${sparkle}`
        return (
          <BannerSparkle
            className={className}
            key={index}
          />
        )
      })}
      <FlexWrapper>
        <TextContainer>
          <StyledText textAlign="center" fontSize="72px">
            {header}
          </StyledText>
          {isSubheader && (
            <StyledText
              textAlign="center"
              fontSize="24px"
              fontType="regular"
              fontWeight={400}
            >
              {subHeader}
            </StyledText>
          )}
          {/* <StyledButton color="#06C68F">
            Watch Video{' '}
            <FontAwesomeIcon icon={faPlay} className="button-icon" />
          </StyledButton> */}
        </TextContainer>
      </FlexWrapper>
    </WaveWrapper>
  )
}

export default BannerSection
