import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 165px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 70px 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 70px 20px;
  }
`

export const ArticleContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 40px;

  & > :first-child {
    width: 40%;
    & > .last-paragraph {
      margin: 20px 0;
    }

    .link {
      display: inline-block;
    }
  }
  & > :last-child {
    height: 100%;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.ws}) {
    flex-direction: column-reverse;

    & > :first-child {
      width: 100%;
    }

    & > :last-child {
      margin-bottom: 40px;
    }

    & > div {
      .link {
        width: 100%;
      }
      .custom-button {
        justify-content: center;
        width: 100%;
        margin-block: 30px;
      }
      .last-paragraph {
        display: none;
      }
    }
  }
`
