import {
  FlexArticle,
  InnerArticle,
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import React from 'react'
import {
  IntroduceArticle,
  Wrapper,
} from './styled-components'

import WebsiteWomanSvg from 'images/svg/websiteWomanReversed.svg'
import { FlexArticleContainer } from 'sections/HomePage/Features/styled-componens'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'

const ProvideSection = ({
  title,
  description,
  innerArticleTexts,
  articles,
}: {
  title: string
  description: {
    description: string
  }
  innerArticleTexts: string[]
  articles: {
    title: string
    description: string
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.flag,
    currentIconEnums.share,
    currentIconEnums.videoCam,
    currentIconEnums.wavesReverse,
  ]
  return (
    <WaveWrapper color="lightGrey">
      <Wrapper>
        <IntroduceArticle>
          <div>
            <StyledText className="top-responsive">
              {title}
            </StyledText>
            <StyledText
              fontType="regular"
              fontWeight={400}
              // textColor="rgba(99, 99, 105, 1)"
              fontSize="16px"
              className="subheader"
            >
              {description.description}
            </StyledText>
            {innerArticleTexts.map(
              (innerArticle, index) => (
                <InnerArticle
                  key={index}
                  margins={[8, 0]}
                  iconWidth={20}
                >
                  {innerArticle}
                </InnerArticle>
              ),
            )}
          </div>
          <WebsiteWomanSvg />
          <StyledText className="responsive heading">
            {title}
          </StyledText>
        </IntroduceArticle>
        <FlexArticleContainer>
          {articles.map((article, index) => {
            return (
              <FlexArticle
                width="20%"
                key={index}
                icon={
                  articleIconEnums[
                    index % articleIconEnums.length
                  ]
                }
                {...article}
                gap={[16, 8]}
              />
            )
          })}
        </FlexArticleContainer>
        <StyledButton
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
          paddings={74}
        >
          See it in action
        </StyledButton>
      </Wrapper>
    </WaveWrapper>
  )
}

export default ProvideSection
