import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(207, 175, 235, 0.1);
  padding: 65px 165px;
  gap: 38px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 65px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 65px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 65px 20px;
  }
`

export const ArticleContainer = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth};
  padding: 0 8%;
  gap: 30px;

  .subheader {
    padding: 0 !important;
  }
  .title {
    width: 50%;
  }

  .inner-article-container {
    p {
      font-family: 'Poppins-bold';
    }

    & > div {
      .content {
        font-family: 'Poppins-regular';
        font-weight: normal;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column;
    align-items: flex-start;
    .title {
      width: 100%;
    }

    padding: 0;
  }
`
