import { StyledText } from 'components/common'
import React from 'react'
import {
  ArticleContainer,
  ComponentWrapper,
  Wrapper,
} from './styled-components'

import LibraSvg from 'images/svg/libra.svg'
import QuizDoneSvg from 'images/svg/done.svg'
import VideoCallSvg from 'images/svg/videoCall.svg'
import CertificateSvg from 'images/svg/certificate.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { DigitalPortfolioProps } from 'interfaces/PageInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'

const OrganizingSection = ({
  header,
  subHeader,
  progression,
  score,
  skillTestVideo,
  certification,
  mobileScreenshot,
}: DigitalPortfolioProps) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      {isValidElement(subHeader) && (
        <StyledText
          variant="light"
          textAlign="center"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <ArticleContainer>
        <ComponentWrapper>
          {isValidElement(progression) && (
            <div>
              <LibraSvg />
              <p>{progression}</p>
            </div>
          )}
          {isValidElement(score) && (
            <div>
              <QuizDoneSvg />
              <p>{score}</p>
            </div>
          )}
          {isValidElement(skillTestVideo) && (
            <div>
              <VideoCallSvg />
              <p>{skillTestVideo}</p>
            </div>
          )}
          {isValidElement(certification) && (
            <div>
              <CertificateSvg />
              <p>{certification}</p>
            </div>
          )}
        </ComponentWrapper>
        <GatsbyImage
          image={mobileScreenshot.gatsbyImage}
          alt="portfolio"
        />
      </ArticleContainer>
    </Wrapper>
  )
}

export default OrganizingSection
