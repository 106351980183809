import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 55px;
  align-items: center;
  background-color: rgba(207, 175, 235, 0.1);
  padding: 65px 165px;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 65px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 65px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 65px 20px;
    gap: 35px;

    .link,
    .custom-button {
      width: 100%;
      margin-top: 5px;
    }
  }
`

export const FlexContainer = styled.article`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.placeholders.maxWidth};
  align-items: flex-start;
  padding: 0 8%;
  gap: 50px;

  .subheader {
    padding: 0 !important;
    white-space: break-spaces;
  }

  .title {
    width: 50%;
  }

  .inner-container {
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;

    & > div {
      display: flex;
      // justify-content: space-between;
      align-items: flex-start;
      gap: 30px;

      & > :first-child {
        width: 50px;
      }
    }

    .text-container {
      & > p {
        margin: 0;
        width: 100%;
      }
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column;

    .title {
      width: 100%;
      text-align: center;
    }
    .inner-container {
      width: 100%;
      & > div {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`
