import React from 'react'
import {
  FlexArticle,
  StyledButton,
  StyledText,
} from 'components/common'
import {
  FlexArticleContainer,
  Wrapper,
} from './styled-components'

const SuadaHelpSection = ({
  articles,
}: {
  articles: {
    title: string
    description: string
  }[]
}) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        Discover how Suada can help you bridge the digital
        gap
      </StyledText>
      <FlexArticleContainer>
        {articles.map((article, index) => {
          return (
            <FlexArticle
              key={index}
              aligment="center"
              icon={index + 1}
              {...article}
              gap={[16, 8]}
            />
          )
        })}
      </FlexArticleContainer>
      <StyledButton
        to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        paddings={74}
      >
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default SuadaHelpSection
