import { StyledText } from 'components/common'
import React from 'react'
import {
  ScreensContainer,
  Wrapper,
} from './styled-components'

import Screens from './ScreensData'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { PaginationOptions } from 'swiper/types'
import { SwiperContainer } from 'components/carousels/ScreensSlider/styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  GatsbyImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'

const MobileScreensSection = ({
  header,
  subHeader,
  screens,
}: {
  header: string
  subHeader: string
  screens: {
    title: string
    screenshot: {
      gatsbyImage: IGatsbyImageData
    }
  }[]
}) => {
  const mobileScreensData = useStaticQuery(
    SCREENSHOTS_QUERY,
  ).allFile.nodes
  const pagination:
    | boolean
    | PaginationOptions
    | undefined = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span key=${index} class=${className}></span>`
    },
  }
  const isSubheader =
    subHeader !== undefined && subHeader !== '!'
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        {header}
      </StyledText>
      {isSubheader && (
        <StyledText
          fontWeight={400}
          fontType="regular"
          fontSize="16px"
          textAlign="center"
          textColor="rgba(99, 99, 105, 1)"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <ScreensContainer>
        {screens.map(({ title, screenshot }, index) => {
          return (
            <div key={index}>
              <StyledText fontSize="19px">
                {title}
              </StyledText>
              <GatsbyImage
                image={screenshot.gatsbyImage}
                alt="screenshot"
              />
            </div>
          )
        })}
      </ScreensContainer>
      <SwiperContainer>
        <Swiper
          className="screenshot-slider"
          slidesPerView={1}
          autoplay={true}
          pagination={pagination}
        >
          {Screens.map(
            ({ title, relativePath: path }, index) => {
              const imageFluid = mobileScreensData.find(
                (screen: any) =>
                  screen.relativePath === path,
              )?.childImageSharp.fluid
              return (
                <SwiperSlide className="slide" key={index}>
                  <h6>{title}</h6>
                  <Img
                    fluid={imageFluid}
                    alt="screenshot"
                  />
                </SwiperSlide>
              )
            },
          )}
        </Swiper>
      </SwiperContainer>
    </Wrapper>
  )
}

export default MobileScreensSection

const SCREENSHOTS_QUERY = graphql`
  query MobileScreenshots {
    allFile(
      filter: {
        relativeDirectory: { eq: "images/screenshots" }
        extension: { eq: "png" }
      }
    ) {
      nodes {
        relativePath
        relativeDirectory
        base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
