import React from 'react'
import {
  InnerArticle,
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import {
  FlexArticleContainer,
  IntroduceSection,
  Wrapper,
} from './styled-components'

import WebsiteWomanSvg from 'images/svg/websiteWoman.svg'

const DigitalLearningSection = ({
  mission,
  description,
  digitalGap,
}: {
  mission: string
  description: string
  digitalGap: {
    title: string
    footer: string
    innerArticleTexts: string[]
  }
}) => {
  const { title, footer, innerArticleTexts } = digitalGap

  const isDescription =
    description !== undefined && description !== '!'

  const isFooter = footer !== undefined && footer !== '!'

  return (
    <>
      <IntroduceSection>
        {isDescription && (
          <StyledText
            fontType="regular"
            fontWeight={400}
            fontSize={'24px'}
            textColor={'rgba(99, 99, 105, 1)'}
          >
            {description}
          </StyledText>
        )}
        <StyledText className="label">{mission}</StyledText>
      </IntroduceSection>
      <WaveWrapper color="lightGrey">
        <Wrapper>
          <FlexArticleContainer>
            <div>
              <StyledText className="top-responsive">
                {title}
              </StyledText>
              {innerArticleTexts.map(
                (innerArticle, index) => (
                  <InnerArticle priority="low" key={index}>
                    {innerArticle}
                  </InnerArticle>
                ),
              )}
              <div className="book-demo">
                {isFooter && (
                  <StyledText>{footer}</StyledText>
                )}
                <StyledButton
                  color="#8636CC"
                  to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
                  paddings={73}
                >
                  See it in action
                </StyledButton>
              </div>
            </div>
            <WebsiteWomanSvg />
            <StyledText className="responsive">
              {title}
            </StyledText>
          </FlexArticleContainer>
        </Wrapper>
      </WaveWrapper>
    </>
  )
}

export default DigitalLearningSection
