import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  ${({ theme }) => theme.placeholders.maxWidth};

  .responsive {
    display: none;
    text-align: center;
  }

  padding: 100px 165px 40px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wl}) {
    padding: 100px 100px 40px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 40px 50px 20px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 30px 20px 20px 20px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 100px 20px;
    .responsive {
      display: block;
    }
    .top-responsive {
      display: none;
    }

    .link,
    .link .custom-button {
      width: 100%;
      justify-content: center;
    }
  }
`

export const IntroduceArticle = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > :first-child {
    width: 50%;

    .subheader {
      white-space: break-spaces;
      padding: 0 !important;
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column-reverse;

    & > :first-child {
      width: 100%;
      margin-top: 25px;
    }

    & > svg {
      width: 90%;
      margin-block: 40px;
    }
  }
`
