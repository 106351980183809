import React from 'react'
import {
  FlexArticle,
  StyledButton,
  StyledText,
} from 'components/common'
import { FlexContainer, Wrapper } from './styled-components'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'
import { isValidElement } from 'helpers/defineHtmlAst'

const EnchanceSection = ({
  header,
  subHeader,
  articles,
}: {
  header: string
  subHeader: string
  articles: {
    title: string
    description: string
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.waves,
    currentIconEnums.TwoXTwo,
    currentIconEnums.ThreeXThreePurple,
    currentIconEnums.waves,
  ]

  return (
    <Wrapper>
      <StyledText className="heading">{header}</StyledText>
      {isValidElement(subHeader) && (
        <StyledText
          textAlign="center"
          variant="light"
          className="subheader max-width"
        >
          {subHeader}
        </StyledText>
      )}
      <FlexContainer>
        {articles.map((article, index) => {
          return (
            <div className="flexWrapper" key={index}>
              <FlexArticle
                themeColor="#7C2AC3"
                icon={
                  articleIconEnums[
                    index % articleIconEnums.length
                  ]
                }
                {...article}
                gap={[16, 8]}
                width="100%"
              />
            </div>
          )
        })}
      </FlexContainer>
      <StyledButton
        to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        paddings={74}
      >
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default EnchanceSection
