import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15%;
  gap: 64px;
  ${({ theme }) => theme.placeholders.maxWidth};

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 40px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 40px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 30px 20px;

    .link,
    .custom-button {
      width: 100%;
    }
  }
`

export const FlexContainer = styled.article`
  display: flex;
  flex-direction: column;
  gap: 45px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    div {
      align-items: center;

      p {
        text-align: center;
      }
    }
  }
`
